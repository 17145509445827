<template>
  <div class="wit-timeline-node w-l-row-center-compact" :class="{'active': isActive}" @click="nodeCall" @mouseenter="nodeCall">
    <span class="pointer">&nbsp;</span>
    <div class="name font-24">
      {{ name }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "WitTimelineNode",
    props:{
      mode: {
        type: String,
        default:() => 'click'
      },
      callback:{
        type: Function,
        require: true
      },
      swiperPos:{
        type: Number
      },
      name: String,
      index: Number,
      currentIndex: {
        type: Number,
        default:() => 0
      }
    },
    methods:{
      nodeCall(e){
        if(this.mode === e.type) this.callback(e, this.swiperPos, this.index);
      }
    },
    computed:{
      isActive(){
        return this.index === this.currentIndex
      }
    }
  }
</script>

<style scoped>
  .wit-timeline-node{
    position: relative;
    width: 100px;
    height: 50px;
    cursor: pointer;
    background-color: transparent;
    margin: -3px 4px 4px;
  }
  .wit-timeline-node.active:before{
    content: '';
    width: 1px;
    height: 100px;
    display: block;
    top: -100px;
    left: 50%;
    margin-left: -0.5px;
    background-image: linear-gradient(0deg,rgba(106,202,255,0.40) 43%,rgba(106,202,255,0.00) 100%);
    position: absolute;
  }
  .wit-timeline-node.active:after{
    content: '';
    display: block;
    position: absolute;
    height: 10px;
    width: 1px;
    background-image: linear-gradient(0deg,rgba(106,202,255,0.00) 0%,#6ACAFF 50%,rgba(106,202,255,0.00) 100%);
    top: 0;
    left: 50%;
    margin-left: -0.5px;
    animation: mvup 2s linear infinite;
  }
  @keyframes mvup
  {
    from {
      top:0
    }
    to {
      top: -90px
    }
  }

  @-webkit-keyframes mvup /*Safari and Chrome*/
  {
    from {
      top:0
    }
    to {
      top: -90px
    }
  }
  .wit-timeline-node .pointer{
    width: 22px;
    height: 22px;
    display: block;
    position: absolute;
    border-radius: 100%;
    left: 50%;
    top: -11px;
    margin-left: -11px;
    z-index: 4;
    /*background-color: red;*/
  }
  .wit-timeline-node .pointer:before{
    content: ' ';
    display: block;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    border-radius: 100%;
    background: transparent;
    transition: all 0.5s linear;
  }
  .wit-timeline-node .pointer:after{
    content: ' ';
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #2B52CB;
    border: 4px solid #929AD8;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -7px;
    margin-top: -7px;
    transition: all 0.5s linear;
    z-index: 3;
  }
  .wit-timeline-node.active .pointer:before{
    background: #6ACAFF;
  }
  .wit-timeline-node.active .pointer:after{
    width: 20px;
    height: 20px;
    margin-left: -10px;
    margin-top: -10px;
    background: #6ACAFF;
    border: 6px solid #3A4792;
  }
  .wit-timeline-node .name{
    padding-top: 30px;
    color: rgba(255,255,255,.4);
  }
  .wit-timeline-node.active .name,
  .wit-timeline-node:hover .name{
    color: white;
  }
</style>
