<template>
  <div class="wit-timeline w-l-column-center-compact">
    <wit-timeline-wrapper class="history-timeline" :progress-width="progressWidth">
      <template v-slot:details>
        <node-details :node-items="nodeList" :current-index="currentIndex"></node-details>
      </template>
      <wit-timeline-node v-for="(node, index) in nodeList" :key="index" :index="index"
                         :current-index="currentIndex"
                         :callback="NodeCallback"
                         :mode="mode"
                         :swiper-pos="swiperPosList[index]"
                         :name="node.name"></wit-timeline-node>
    </wit-timeline-wrapper>
  </div>
</template>

<script>
  import WitTimelineWrapper from "@/components/content/WitTimeline/WitTimelineWrapper";
  import WitTimelineNode from "@/components/content/WitTimeline/WitTimelineNode";
  import NodeDetails from "@/components/content/WitTimeline/NodeDetails";
  export default {
    name: "WitTimeline",
    components:{
      WitTimelineWrapper,
      WitTimelineNode,
      NodeDetails
    },
    props:{
      nodeList:{
        type: Array,
        default: () => []
      },
      mode:{
        type: String,
        // default: () => 'mouseenter'
        default: () => 'click'
      }
    },
    data(){
      return {
        currentIndex: 1,
        progressWidth: 0
      }
    },
    updated() {
      setTimeout(()=>{
        const node = document.querySelector('.wit-timeline .history-timeline .wit-timeline-node.active')
        this.progressWidth =  node.offsetLeft + node.clientWidth / 2
      }, 0)
    },
    methods:{
      NodeCallback(e, swiperPos, index){
        if(e.target.classList.contains('wit-timeline-node')){
          this.progressWidth = e.target.offsetLeft + e.target.clientWidth/2
        } else {
          this.progressWidth = e.target.parentElement.offsetLeft + e.target.parentElement.clientWidth/2
        }

        this.currentIndex = index
        this.$emit('swiper', swiperPos)
      }
    },
    computed:{
      swiperPosList(){
        const swiperFull = 23
        const cnt = this.nodeList.length
        let pos = []
        for(let i = 0; i < cnt; i++){
          pos.push(i*swiperFull/cnt)
        }
        return pos
      },
      nodeDetail(){
        return this.nodeList[this.currentIndex]
      }
    }
  }
</script>

<style scoped>
  .wit-timeline .history-timeline{
    position: relative;
    width: 100%;
    height: 100%;
  }

</style>
