<template>
  <div class="wit-rolling">
    <div class="inner-rolling"></div>
    <div class="content">
      <div class="item item-center" v-for="(item, index) in items" :style="{'transform': itemsPosition[index]}">
        <div class="icon">

        </div>
        <div class="name">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WitRolling",
    props:{
      items:{
        type: Array,
        default:() => []
      }
    },
    computed:{
      itemsPosition(){
        const r = 290;
        const cnt = this.items.length;
        const step = 360 / cnt;
        let pos = [];
        for(let i = 0; i < cnt ; i++){
          let y = - r * Math.cos((i*step) * Math.PI / 180)
          let x = r * Math.sin((i*step) * Math.PI / 180)
          pos.push("translateY("+Math.round(y)+"px) translateX("+Math.round(x)+"px)")
        }
        return pos
      }
    }
  }
</script>

<style scoped>
  .wit-rolling-container{
    width: 100%;
    height: 100%;
  }
  .wit-rolling{
    min-height: 871px;
    height: 871px;
    position: relative;
    background: url("/images/aboutus/dot_ball.png") center no-repeat;
    background-size: 285px;
    overflow: visible;
  }
  .wit-rolling:before{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 871px;
    height: 871px;
    margin-left: -435.5px;
    margin-top: -435.5px;
    background: url("/images/aboutus/roll_bg_r.png") center no-repeat;
    background-size: 100%;
    animation: rolling 60s infinite linear;
    -webkit-animation: rolling 60s infinite linear;
  }
  .wit-rolling:after{
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1122px;
    height: 1122px;
    margin-left: -566px;
    margin-top: -566px;
    background: url("/images/aboutus/roll_bg_w.png") center no-repeat;
    background-size: 100%;
    animation: rolling-anti 60s infinite linear;
    -webkit-animation: rolling-anti 60s infinite linear;
  }
  .wit-rolling .inner-rolling{
    background: url("/images/aboutus/roll_bg_in.png") center no-repeat;
    background-size: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 583px;
    height: 583px;
    margin-left: -291.5px;
    margin-top: -291.5px;
    animation: rolling-anti 60s infinite linear;
    -webkit-animation: rolling-anti 60s infinite linear;
  }
  .wit-rolling .content{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .wit-rolling .item{
    width: 116px;
    height: 116px;
    position: absolute;
    z-index: 10;
    left: calc(50% - 58px);
    right: auto;
    top: calc(50% - 58px);
    bottom: auto;
  }
  .wit-rolling .item:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url("/images/aboutus/roll_bg_item_hover.png") center no-repeat;
    background-size: 100%;
    opacity: 0;
  }
  .wit-rolling .item:after{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url("/images/aboutus/roll_bg_item.png") center no-repeat;
    background-size: 100%;
    animation: rolling 30s infinite linear;
    -webkit-animation: rolling 30s infinite linear;
  }
  .wit-rolling .item:hover:before{
    opacity: 1;
  }
  .wit-rolling .item:hover:after{
    opacity: 0;
  }
  .wit-rolling .item>div{
    position: relative;
    z-index: 5;
  }
  .wit-rolling .item .name{
    font-size: 14px;
    line-height: 18px;
    color: #666;
    width: 60px;
    text-align: center;
  }

  @keyframes rolling
  {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rolling /*Safari and Chrome*/
  {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes rolling-anti
  {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @-webkit-keyframes rolling-anti /*Safari and Chrome*/
  {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
</style>
