<template>
  <wit-block-content-container class="aboutus-address">
    <div class="panel-title fc-black">
      办公地点
    </div>
    <div class="location-container">
      <div class="location-bg"></div>
      <div class="locations w-l-row-center-compact fc-white">
        <div class="loc-icon-block item-center">
          <i class="iconfont icon-location"></i>
          <div class="wave-container">
            <!--                <div class="wave wave1"></div>-->
            <div class="wave wave0 top"></div>
          </div>
        </div>
        <div class="address-list-block">
          <ul class="item-column-center">
            <li class="address-li w-l-column-rs-compact fc-white" v-for="(item, index) in companyInfo" :key="index">
              <h3 class="name">{{item.company}}</h3>
              <p class="address">{{item.location}}</p>
            </li>
            <!--                <li class="address-li item-column-center">-->
            <!--                  <h3 class="name">沃丁科技（上海）</h3>-->
            <!--                  <p class="address">上海市·奉贤区·望园南路·1518弄·A1座·1206室</p>-->
            <!--                </li>-->
          </ul>
        </div>
      </div>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import {getCompanyAddrData} from "@/network/aboutus";
  export default {
    name: "AboutusAddress",
    components:{
      WitBlockContentContainer
    },
    created() {
      getCompanyAddrData().then(response => {
        this.companyInfo = response
      })
    },
    data(){
      return{
        companyInfo: []
      }
    }
  }
</script>

<style scoped>
  .aboutus-address{
    margin: 100px 0;
  }
  .aboutus-address .panel-title{
    width: 100%;
    text-align: center;
    padding: 0 0 100px;
  }
  .aboutus-address .location-container{
    position: relative;
    width: 100%;
    height: 500px;
  }
  .aboutus-address .location-container .location-bg{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("/images/aboutus/shanghai.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .aboutus-address .location-container .locations{
    position: relative;
    height: 100%;
  }
  .aboutus-address .location-container .locations ul{
    list-style: none;
    height: 100%;
  }
  .aboutus-address .location-container .locations .loc-icon-block{
    height: 100%;
    width: 30%;
    border-right: rgba(255, 255, 255, 0.5) solid 1px;
  }
  .aboutus-address .location-container .locations .loc-icon-block i{
    font-size: 100px;
  }
  .aboutus-address .location-container .locations .address-list-block{
    height: 100%;
    width: 70%;
  }
  .aboutus-address .location-container .locations .address-li{
    margin: 20px 0;
    border-bottom: rgba(255, 255, 255, 0.5) solid 1px;
  }
  .aboutus-address .location-container .locations .name{
    margin: 10px 0;
  }
  .aboutus-address .location-container .locations .address{
    margin: 5px 0;
  }
  .aboutus-address .location-container .locations .wave-container{
    position: absolute;
    top: calc(50% + 50px)
  }
  .wave{
    position:absolute;
    top:calc((100% - 30px)/2);
    left:calc((100% - 30px)/2);
    width:30px;
    height:30px;
    border-radius:50% / 50%;
    transform: rotate3d(1, 0, 0, 70deg);
  }
  .wave0{
    background:transparent;
    border: white solid 2px;
    z-index:2;
    /*background-size:auto 106%;*/
    animation:w 2s forwards infinite;
  }
  .wave0.top{
    border-top-color: #ffffff00;
  }
  .wave1{
    background:transparent;
    border: white solid 1px;
    z-index:3;
    animation:w 2s 0.5s forwards;
  }
  @keyframes w{
    0%{
      top:calc((100% - 30px)/2);
      left:calc((100% - 30px)/2);
      width:30px;
      height:30px;
    }
    100%{
      top:calc((100% - 150px)/2);
      left:calc((100% - 150px)/2);
      width:150px;
      height:150px;
    }
  }
</style>
