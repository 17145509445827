<template>
  <wit-block-content-container class="aboutus-history" mask-color="rgba(0,0,0,0.4)">
    <template v-slot:pre-append>
      <div class="aboutus-history-bg" :style="{'transition-duration': bgTransitionDuration +'ms', 'transform': bgTransform}"></div>
    </template>
    <div class="panel-title fc-white item-center">
      发展历程
    </div>
    <wit-timeline class="aboutus-history-timeline" @swiper="swiper" :node-list="chronicleList"></wit-timeline>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import WitTimeline from "@/components/content/WitTimeline/WitTimeline";
  import {getHistoryData} from "@/network/aboutus";
  export default {
    name: "AboutusHistory",
    components:{
      WitBlockContentContainer,
      WitTimeline
    },
    data(){
      return{
        historyData: null,
        bgTransitionDuration : 3000,
        bgTransform: 'translate3d(0, 0, 0)'
      }
    },
    created() {
      getHistoryData().then(response => {
        this.historyData = response
      })
    },
    computed:{
      chronicleList(){
        let tmpList = {};
        let lastTitle = '';
        for(let i in this.historyData){
          let obj = {};
          let name = this.historyData[i].eventYear;
          let title = this.historyData[i].eventMonth;
          if (lastTitle === title) {
            obj['title'] = ''
          } else {
            obj['title'] = title
            lastTitle = obj['title']
          }


          obj['description'] = this.historyData[i].eventDescription;
          if (name in tmpList){
            tmpList[name].push(obj)
          } else {
            tmpList[name] = [obj]
          }
        }
        let hisList = []
        for (let key in tmpList){
          let obj = {};
          obj['name'] = key;
          obj['chronicle'] = tmpList[key]
          hisList.push(obj)
        }
        return hisList
      }
    },
    methods:{
      swiper(swiperPos){
        this.bgTransform = 'translate3d(-'+swiperPos+'%, 0, 0)'
      }
    }
  }
</script>

<style scoped>
  .aboutus-history{
    position: relative;
    height: 760px;
    width: 100%;
    overflow: hidden;
  }
  .aboutus-history .aboutus-history-bg{
    background-image: url("/images/aboutus/history_bg.jpg");
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .aboutus-history .panel-title{
    width: 100%;
    padding: 50px 0;
    line-height: 60px;
  }
  .aboutus-history .aboutus-history-timeline{
    width: 100%;
  }
</style>
