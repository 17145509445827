<template>
  <div class="wit-timeline-wrapper w-l-column-center-compact">
    <div class="node-details">
      <slot name="details"></slot>
    </div>
    <div class="node-container w-l-column-center-compact">
      <div class="progress" :style="{width: progressWidth+'px'}"></div>
      <div class="content w-l-row-center-around">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WitTimelineWrapper",
    props:{
      progressWidth:{
        type: Number
      }
    }
  }
</script>

<style scoped>
  .wit-timeline-wrapper{
    position: relative;
  }
  .wit-timeline-wrapper .node-container{
    border-top: #0077aa solid 4px;
    width: 100%;
  }
  .wit-timeline-wrapper .progress{
    width: 30%;
    height: 4px;
    transition: all 0.5s;
    background-image: linear-gradient(-90deg,#6ACAFF 0%,#0B1CBA 100%);
    align-self: flex-start;
    margin-top: -4px;
  }
  .wit-timeline-wrapper .node-container .content{
    width: 100%;
  }
  .wit-timeline-wrapper .node-details{
    width: 100%;
    min-height: 500px;
    height: 500px;
    margin-bottom: 15px;
  }
</style>
