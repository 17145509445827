<template>
  <div class="aboutus">
    <el-main class="wit-aboutus-container">
      <aboutus-header></aboutus-header>
      <div class="brief-container">
        <aboutus-brief :brief="brief"></aboutus-brief>
        <wit-rolling class="rolling" :items="mainBusiness"></wit-rolling>
      </div>
      <aboutus-history></aboutus-history>
      <aboutus-address class="address"></aboutus-address>
    </el-main>
  </div>
</template>

<script>
  import AboutusHeader from "@/views/aboutus/AboutusHeader/AboutusHeader";
  import AboutusAddress from "@/views/aboutus/AboutusAddress/AboutusAddress";
  // import AboutusNav from "@/views/aboutus/AboutusNavigation/AboutusNav";
  import AboutusBrief from "@/views/aboutus/AboutusBrief/AboutusBrief";
  import WitRolling from "@/components/content/WitRolling/WitRolling";
  import AboutusHistory from "@/views/aboutus/AboutusHistory/AboutusHistory";
  import {getBusScopeData} from "@/network/aboutus";
  export default {
    name: "AboutUs",
    components:{
      AboutusHeader,
      // AboutusNav,
      AboutusBrief,
      WitRolling,
      AboutusHistory,
      AboutusAddress
    },
    data(){
      return{
        brief: `沃丁科技(上海)有限公司于2020年03月19日成立。成立之初就专注于农业领域的软件，硬件系统技术研发以及打通人工智能感知认知决策闭环链路。`,
        mainBusiness:[]
      }
    },
    created() {
      getBusScopeData().then(response=>{
        this.mainBusiness = this.scopeTrim(response)
      })
    },
    mounted() {
      this.$emit('ContentMounted')
    },
    activated() {
      this.$emit('ContentActivated')
    },
    methods:{
      WitScrollTo(...args){
        this.$emit('WitScrollTo', ...args)
      },
      scopeTrim(data){
        return data.map(item => {return item.title})
      }
    }
  }
</script>

<style scoped>
  .wit-aboutus-container{
    padding: 0 !important;
  }
  .wit-aboutus-container .brief-container{
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .wit-aboutus-container .rolling{
    width: 1200px;
    margin: 0 auto 50px;
  }
</style>
