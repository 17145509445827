<template>
  <wit-block-content-container class="aboutus-brief"
                               content-class="w-l-column-center-compact"
                               :content-style="{'padding': '145px 0 0'}">
    <div class="panel-title fc-black">
      关于沃丁
    </div>
    <div class="brief-block">
      <p>{{brief}}</p>
    </div>
    <div class="clear-fix"></div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  export default {
    name: "AboutusBrief",
    components:{
      WitBlockContentContainer
    },
    props:{
      brief: String
    }
  }
</script>

<style scoped>
  .aboutus-brief{
    width: 100%;
  }
  .aboutus-brief .panel-title{
    margin-bottom: 60px;
    z-index: 2;
  }
  .aboutus-brief .brief-block{
    font-size: 18px;
    color: #666666;
    line-height: 40px;
    z-index: 2;
  }
</style>
