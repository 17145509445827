import {request} from "@/network/request";

export function getBusScopeData(){
  return request({
    url: "/business/scope"
  })
}

export function getHistoryData(){
  return request({
    url: "/chronicle"
  })
}

export function getCompanyAddrData(){
  return request({
    url: "/locations"
  })
}
