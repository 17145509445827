<template>
  <wit-block-content-container class="aboutus-header"
                               bg-img-url="/images/aboutus/au-001.jpg"
                               mask-color="rgba(255,255,255, 0.5)"
                               content-class="w-l-column-rs-compact"
                               :content-style="contentStyle">
    <div class="mask"></div>
    <div class="page-title">
      <p><span class="decoration">|</span>推进AI赋能农业</p>
    </div>
    <div class="gist font-12 fc-gray-white">
      <p>沃丁科技（上海）有限公司，是一家专注于农业领域，拥有独立研发能力及创新力量的技术驱动型AI物联网公司；为客户提供物联网+AI的农业解决方案和产品，不断探索农业新模式，为现代农业赋能。</p>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  export default {
    name: "AboutusHeader",
    components:{
      WitBlockContentContainer
    },
    data(){
      return {
        contentStyle:{
          'background-image': 'url("/images/aboutus/au-001.jpg")',
          'background-position': '50% 50%',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'padding-left': '70px'
        }
      }
    },
  }
</script>

<style scoped>
  .aboutus-header{
    width: 100%;
    height: 600px;
  }
  .aboutus-header .mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.35);
  }
  .aboutus-header .page-title{
    line-height: 56px;
    letter-spacing: 2px;
    margin-bottom: 20px;
    z-index: 1;
  }
  .aboutus-header .page-title .decoration{
    color: red;
    font-weight: 1;
    margin-right: 20px;
  }
  .aboutus-header .gist{
    letter-spacing: 1px;
    width: 500px;
    line-height: 25px;
    z-index: 1;
  }
</style>
