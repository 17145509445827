<template>
  <div class="wit-node-details">
    <el-carousel ref="detailCarousel" height="100%" :autoplay="false" arrow="never" :initial-index="this.currentIndex" indicator-position="none">
      <el-carousel-item v-for="(nodeDetail, index) in nodeItems" :key="index">
        <div class="details item-center">
          <div class="year item-column-center">
            {{ nodeDetail.name }}
          </div>
          <div class="mouth-activities item-column-center">
            <el-timeline>
              <el-timeline-item
                v-for="(node, index) in nodeDetail.chronicle"
                :key="index"
                :timestamp="node.title" placement="top">
                {{ node.description }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  export default {
    name: "NodeDetails",
    props:{
      nodeItems:{
        type: Array,
        default:() => []
      },
      currentIndex: Number
    },
    watch:{
      currentIndex(){
        this.$refs.detailCarousel.setActiveItem(this.currentIndex)
      }
    }
  }
</script>

<style scoped>
  .wit-node-details{
    height: 100%;
  }
  .wit-node-details .details{
    width: 100%;
    height: 100%;
    color: white;
  }
  .wit-node-details .details .year{
    height: 100%;
    width: 15%;
    font-size: 64px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: bold;
    align-items: flex-end;
    margin-left: 200px;
  }
  .wit-node-details .details .mouth-activities{
    height: 100%;
    width: 45%;
    margin-left: 100px;
    align-items: flex-start;
  }
</style>
